import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import { FaAngleDoubleRight } from "react-icons/fa"

const BlogTemplate = ({ data }) => {
  const content = data.strapiProject.content?.data?.content || ""
  return <Layout>
    <section className="blog-template">
      <div className="section-center">
        <article className="blog-content">
          <ReactMarkdown children={content} components={{
            blockquote: 'h4',
            li: ({ node, ...props }) => {
              return (<>
                <div key={props.index} className="project-bullet">
                  <FaAngleDoubleRight className="project-bullet-icon" />
                  <p>{props.children}</p></div>
              </>)
            }
          }} />
          <div className="section-center project-stack">
            {data.strapiProject.stack.map(item => {
              return <span key={item.id}>{item.title}</span>
            })}
          </div>
        </article>
        <Link to="/projects" className="btn center-btn" >back to projects</Link>
      </div>
    </section>
  </Layout >
}


export const query = graphql`
  query GetSingleProject ($slug: String) {
    strapiProject(slug: {eq: $slug}) {
      content {
        data {
          content
        }
      }
      stack {
            id
            title
      }
    }
  }
`

export default BlogTemplate
